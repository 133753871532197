@keyframes typing {
  from {
    width: 0;
  }

  to {
    width: 100%;
  }
}

@keyframes blink-caret {
  from,
  to {
    border-color: transparent;
  }

  50% {
    border-color: #000;
  }
}

@keyframes slide-up {
  0% {
    opacity: 0;
    transform: translateY(28px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes input-slide-up {
  0% {
    opacity: 0;
    transform: translateY(30px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
