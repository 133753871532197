@import url("https://fonts.googleapis.com/css2?family=Hind+Madurai:wght@300;400;500;600;700&display=swap");

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-LightItalic.eot");
    src: local("Urbane Light Italic"), local("Urbane-LightItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-LightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-LightItalic.woff2")
            format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-LightItalic.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-LightItalic.ttf")
            format("truetype");
    font-weight: 300;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-Medium.eot");
    src: local("Urbane Medium"), local("Urbane-Medium"),
        url("./assets/fonts/UrbaneDemi/Urbane-Medium.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-Medium.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-Medium.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-Medium.ttf") format("truetype");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-ThinItalic.eot");
    src: local("Urbane Thin Italic"), local("Urbane-ThinItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-ThinItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-ThinItalic.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-ThinItalic.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-ThinItalic.ttf")
            format("truetype");
    font-weight: 100;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-DemiBold.eot");
    src: local("Urbane Demi Bold"), local("Urbane-DemiBold"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBold.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBold.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBold.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBold.ttf") format("truetype");
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-Heavy.eot");
    src: local("Urbane Heavy"), local("Urbane-Heavy"),
        url("./assets/fonts/UrbaneDemi/Urbane-Heavy.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-Heavy.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-Heavy.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-Heavy.ttf") format("truetype");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-Thin.eot");
    src: local("Urbane Thin"), local("Urbane-Thin"),
        url("./assets/fonts/UrbaneDemi/Urbane-Thin.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-Thin.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-Thin.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-Thin.ttf") format("truetype");
    font-weight: 100;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-BoldItalic.eot");
    src: local("Urbane Bold Italic"), local("Urbane-BoldItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-BoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-BoldItalic.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-BoldItalic.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-BoldItalic.ttf")
            format("truetype");
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-ExtraLight.eot");
    src: local("Urbane Extra Light"), local("Urbane-ExtraLight"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLight.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLight.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLight.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLight.ttf")
            format("truetype");
    font-weight: 200;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-HeavyItalic.eot");
    src: local("Urbane Heavy Italic"), local("Urbane-HeavyItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-HeavyItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-HeavyItalic.woff2")
            format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-HeavyItalic.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-HeavyItalic.ttf")
            format("truetype");
    font-weight: 900;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-Bold.eot");
    src: local("Urbane Bold"), local("Urbane-Bold"),
        url("./assets/fonts/UrbaneDemi/Urbane-Bold.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-Bold.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-Bold.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-Bold.ttf") format("truetype");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-MediumItalic.eot");
    src: local("Urbane Medium Italic"), local("Urbane-MediumItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-MediumItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-MediumItalic.woff2")
            format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-MediumItalic.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-MediumItalic.ttf")
            format("truetype");
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-DemiBoldItalic.eot");
    src: local("Urbane Demi Bold Italic"), local("Urbane-DemiBoldItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBoldItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBoldItalic.woff2")
            format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBoldItalic.woff")
            format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-DemiBoldItalic.ttf")
            format("truetype");
    font-weight: 600;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-Light.eot");
    src: local("Urbane Light"), local("Urbane-Light"),
        url("./assets/fonts/UrbaneDemi/Urbane-Light.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-Light.woff2") format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-Light.woff") format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-Light.ttf") format("truetype");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "UrbaneDemi";
    src: url("./assets/fonts/UrbaneDemi/Urbane-ExtraLightItalic.eot");
    src: local("Urbane Extra Light Italic"), local("Urbane-ExtraLightItalic"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLightItalic.eot?#iefix")
            format("embedded-opentype"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLightItalic.woff2")
            format("woff2"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLightItalic.woff")
            format("woff"),
        url("./assets/fonts/UrbaneDemi/Urbane-ExtraLightItalic.ttf")
            format("truetype");
    font-weight: 200;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: "Futura";
    font-style: normal;
    font-display: swap;
    font-weight: 300;
    src: local("Futura"),
        url("./assets/fonts/Futura/Futura-Light-font.otf") format("opentype");
}

@font-face {
    font-family: "Futura";
    font-style: normal;
    font-display: swap;
    font-weight: 400;
    src: local("Futura"),
        url("./assets/fonts/Futura/Futura-Book-font.otf") format("opentype");
}

@font-face {
    font-family: "Futura";
    font-style: normal;
    font-display: swap;
    font-weight: 500;
    src: local("Futura"),
        url("./assets/fonts/Futura/Futura-Medium-font.otf") format("opentype");
}

@font-face {
    font-family: "Futura";
    font-style: normal;
    font-display: swap;
    font-weight: 700;
    src: local("Futura"),
        url("./assets/fonts/Futura/Futura-Bold-font.otf") format("opentype");
}

@font-face {
    font-family: "Futura";
    font-style: normal;
    font-display: swap;
    font-weight: 800;
    src: local("Futura"),
        url("./assets/fonts/Futura/Futura-Heavy-font.otf") format("opentype");
}

@font-face {
    font-family: "Futura";
    font-style: normal;
    font-display: swap;
    font-weight: 900;
    src: local("Futura"),
        url("./assets/fonts/Futura/Futura-Extra-Black-font.otf")
            format("opentype");
}

iframe { display: none }

