.conversation-text {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 20px;
}

.conversation-text.is-typing {
  animation: typing 3.5s steps(50, end), blink-caret 0.75s step-end infinite;
  overflow: hidden;
  border-right: 0.15em solid orange;
  white-space: nowrap;
  max-width: fit-content;
}

.conversation-buttons {
  max-width: 60%;
  width: 100%;
  opacity: 0;
  animation: slide-up 0.5s ease-in-out;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;
}

.conversation-buttons .conversation-options {
  background-color: #fff;
  box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
  border-radius: 27px;
  height: 54px;
  border: 4px solid;
  width: fit-content;
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding: 8px 30px;
  box-sizing: border-box;
  margin-bottom: 15px;
  margin-left: 0px;
  margin-right: 15px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}

.conversation-buttons .conversation-options:first-child {
  margin-left: 0px;
}

.conversation-input-wrapper {
  width: 100%;
  border-radius: 30px;
  background: $white-color;
  display: flex;
  border: 2px solid $primary-color;
  align-items: center;
  box-shadow: 0 1px 28px 0 rgba($color: $black-color, $alpha: 0.22);
  opacity: 0;
  animation: input-slide-up 0.5s ease-in-out;
  animation-delay: 0.8s;
  animation-fill-mode: forwards;

  .conversation-input-startAdornment {
    padding-left: 10px;
    padding-right: 10px;
    font-weight: 500;
    font-size: 17px;

    + .conversation-input {
      padding-left: 0px;
    }
  }

  .conversation-input-endAdornment {
    padding-left: 10px;
    padding-right: 5px;
    font-weight: 500;
    font-size: 17px;
    max-width: 60px;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
  }

  .conversation-input {
    flex: 1;
    padding: 12px 30px;
    border: none;
    border-radius: inherit;
    font-size: 17px;
    font-weight: 500;
    outline: none;
    opacity: 1;
    -moz-appearance: textfield;

    + .conversation-input-endAdornment {
      padding-left: 0px;
    }

    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }
  }

  .conversation-input-btn {
    font-size: 17px;
    font-weight: 600;
    color: $white-color;
    background-color: $primary-color;
    border: none;
    margin-right: 20px;
    padding: 5px 10px;
    border-radius: 4px;
    box-shadow: 0px 12px 18px -6px rgba($color: $primary-color, $alpha: 0.53);
    cursor: pointer;
  }
}
