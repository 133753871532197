@media (min-width: 767px) {
  .conversation-input-wrapper {
    width: 60%;
  }
}

@media (max-width: 767px) {
  .conversation-buttons {
    max-width: 100%;
  }

  .conversation-options {
    margin-left: 0px !important;
    margin-right: 15px;
  }
}
