.calendar-card {
  position: relative;
  background-color: #fff;
  border: 1px solid #e4e4e4;
  box-shadow: 0px 4px 25px rgba(0, 0, 0, 0.25);
  border-radius: 8px;
  // margin: auto;
  padding: 29px 25px;
  box-sizing: border-box;

  &.calendar {
    height: 590px;
    margin: auto;
  }

  &.confirmation {
    min-height: 600px;
    margin: auto;
  }

  &.connect {
    min-height: 1183px;
  }

  .calendar-title {
    color: #414141;
    font-size: 20px;
    line-height: 27px;
    font-weight: 700;
    margin-bottom: 38px;
  }

  .calendar-container {
    display: flex;
    height: calc(100% - 55px);

    .calendar-left {
      flex: 60%;

      .calendar-header {
        margin-bottom: 27px;

        .calendar-datepicker {
          display: flex;
          justify-content: space-between;

          .calendar-left-panel {
            display: flex;
            align-items: center;

            .calendar-datepicker-month,
            .calendar-datepicker-year {
              font-size: 16px;
              background: none;
              border: none;
              outline: none;
              font-weight: 400;
              color: #414141;
              padding: 0px;
            }

            .calendar-datepicker-year {
              padding-left: 6px;
            }
          }

          .calendar-right-panel {
            .calendar-datepicker-decrease-month,
            .calendar-datepicker-increase-month {
              padding-left: 10px;
              font-size: 20px;
              background: none;
              border: none;
              outline: none;
              color: #ee4f6c;
              cursor: pointer;

              &:disabled {
                pointer-events: none;
              }
            }

            .calendar-datepicker-increase-month {
              margin-left: 51px;
              margin-right: 20px;
            }
          }
        }
      }

      .calendar-dates {
        .calendar-dates-header {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          text-align: center;

          .calendar-dates-day-name {
            font-size: 14px;
            line-height: 19.07px;
            color: #000;
            font-weight: 400;
            height: 46px;
            width: 46px;
          }
        }

        .calendar-dates-days {
          display: grid;
          grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
          text-align: center;

          .calendar-dates-day {
            font-size: 14px;
            line-height: 19.07px;
            color: #000;
            font-weight: 400;
            height: 46px;
            width: 46px;
            display: flex;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            pointer-events: none;

            &.selectable {
              border-radius: 50px;
              pointer-events: visible !important;
              cursor: pointer;
              font-weight: 700;
            }

            &.selected {
              color: #fff;
            }
          }
        }
      }

      .calendar-timezone {
        margin-top: 36px;

        label {
          color: #414141;
          font-weight: 600;
          font-size: 16px;
          line-height: 21.79px;
        }

        .timezone {
          margin: 10px 0;
          display: flex;
          gap: 12px;
          align-items: center;
          position: relative;

          img {
            flex: 0 1 auto;
          }

          label {
            flex: 1 1 auto;
            font-family: "Open Sans";
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 22px;
            color: #9c9c9c;
            margin: 0;
          }

          select {
            position: absolute;
            inset: 0;
            opacity: 0;
            cursor: pointer;
          }
        }
      }
    }

    .calendar-right {
      flex: 40%;
      overflow-x: hidden;
      overflow-y: auto;
      text-align: center;

      /* width */
      &::-webkit-scrollbar {
        width: 4px;
      }

      /* Track */
      &::-webkit-scrollbar-track {
        // box-shadow: inset 0 0 5px grey;
        border-radius: 5px;
      }

      /* Handle */
      &::-webkit-scrollbar-thumb {
        background: #ee4f6c;
        border-radius: 5px;
      }

      /* Handle on hover */
      &::-webkit-scrollbar-thumb:hover {
        background: #ee4f6c;
      }

      .current-selected-date {
        font-weight: 700;
        font-size: 16px;
        line-height: 21.79px;
        color: #414141;
        margin-bottom: 20px;
      }

      .time {
        border-radius: 4px;
        background-color: transparent;
        width: 100%;
        text-align: center;
        padding: 8px;
        box-sizing: border-box;
        cursor: pointer;
        font-size: 15px;
        font-weight: 700;
        line-height: 20.43px;
        transition: all 0.5s ease-in-out;
        max-width: 194px;
        margin-bottom: 10px;

        &:hover {
          color: #fff;
        }
      }

      .selected {
        display: flex;
        justify-content: space-between;
        max-width: 194px;
        width: 100%;
        margin: auto;

        .confirm-btn,
        .selected-time {
          border: 1px solid #666666;
          border-radius: 4px;
          color: #fff;
          background-color: #666666;
          width: 100%;
          text-align: center;
          padding: 8px;
          box-sizing: border-box;
          cursor: pointer;
          font-size: 15px;
          font-weight: 700;
          line-height: 20.43px;
          transition: all 0.5s ease-in-out;
          max-width: 92px;
          margin-bottom: 10px;
        }

        .confirm-btn {
          color: #fff;
        }
      }
    }
  }

  .confirmation-container {
    .input-field {
      .MuiInputBase-root,
      input {
        background-color: #f5f5f5 !important;
      }
    }

    .PhoneInput {
      padding: 16.5px 14px;
      border-radius: 4px;
      border: 1px solid rgba(0, 0, 0, 0.23);
      background-color: #f5f5f5;

      &:hover {
        border-color: #000;
      }

      .PhoneInputInput {
        border: none;
        background: transparent;
        outline: none;
        font-size: 16px;
        font-weight: 400;
      }
    }
  }
}

@media screen and (max-width: 767px) {
  .calendar-card {
    min-height: 590px;
    height: unset !important;
    
    .calendar-container {
      flex-direction: column;

      .calendar-right {
        .time {
          margin: 10px;
        }
      }

      .calendar-left {
        .calendar-dates {
          overflow-y: auto;
        }
      }
    }
  }
}
