button.react-date-picker-talk-button:disabled {
  background-color: #e5e5e5 !important;
  border-color: #d7d7d7 !important;
  cursor: not-allowed !important;
}

button.react-date-picker-talk-button:disabled:hover {
  border-color: #d7d7d7 !important;
  background-color: #e5e5e5 !important;
  color: #fff !important;
  cursor: not-allowed !important;
}
